import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import ThankYouWrapper from "../components/ThankYouWrapper";
import CardBlog from "../components/CardBlog";

import IconFacebook from "../images/icons/social/facebook-white.svg";
import IconTwitter from "../images/icons/social/twitter-white.svg";
import IconLinkedIn from "../images/icons/social/linkedin-white.svg";

const ThankYouPage1 = ({ data, location: { pathname } }) => {
  const latestArticle = data.recentArticles?.edges
    ? data.recentArticles?.edges[0]?.node
    : undefined;

  return (
    <Layout
      hideFooter
      footerBgClass="bg-white"
      meta={{
        seo: {
          title: "Thank you!",
        },
        path: pathname,
      }}
    >
      <div className="thank-you-page">
        <ThankYouWrapper
          title="Thank you!"
          description=" Your download is on its way to your mailbox! Please double check your spam folder."
          middleBlock={
            <div className="pt-2 middle-block">
              <p className="lead mt-3 middle-description">
                Share this resource with your friends
              </p>
            </div>
          }
          socialLinksBlock={
            <div className="social-buttons-container">
              <a
                href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fadamfard.com%2Fdownload-ux-resources"
                target="_blank"
                rel="noopener noreferrer"
                className="pl-3 text-white align-items-center social-button facebook"
              >
                <IconFacebook className="mr-2" />
                <span>Facebook</span>
              </a>
              <a
                href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fadamfard.com%2Fdownload-ux-resources&text=Check%20these%20Free%20UX%20Design%20Resources%3A"
                target="_blank"
                rel="noopener noreferrer"
                className="pl-3 text-white align-items-center social-button twitter"
              >
                <IconTwitter className="mr-2" />
                <span>Twitter</span>
              </a>
              <a
                href="http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fadamfard.com%2Fdownload-ux-resources&title=Check%20these%20Free%20UX%20Design%20Resources%3A"
                target="_blank"
                rel="noopener noreferrer"
                className="pl-3 text-white align-items-center social-button linkedin"
              >
                <IconLinkedIn className="mr-2" />
                <span>LinkedIn</span>
              </a>
            </div>
          }
          article={<CardBlog key={latestArticle.id} cardData={latestArticle} />}
        />
      </div>
    </Layout>
  );
};

export default ThankYouPage1;

export const query = graphql`
  query ThankYouDownloadQuery {
    recentArticles: allDatoCmsArticle(
      limit: 1
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          cardDescription
          cardImage {
            gatsbyImageData(width: 1024, layout: CONSTRAINED)
          }
          category {
            name
          }
          id
          slug
        }
      }
    }
  }
`;
